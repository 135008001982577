.loading {
    width: 100vw;
    height: 100vh;
    background-color: white;
    display: grid;
    place-items: center;
    max-width: 100%;
  }
  
  .loading > div {
    width: 5vmax;
    height: 5vmax;
    border-bottom: 5px solid rgba(0, 0, 0, 0.719);
  
    border-radius: 50%;
  
    animation: loadingRotate 800ms linear infinite;
  }
  
  @keyframes loadingRotate {
    to {
      transform: rotateZ(-360deg);
    }
  }
  

   /* .search-loader {
    width: 100%;
    text-align: center;
}

.search-loader>img {
    display: inline-block;
    width: 150px; 
} */